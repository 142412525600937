
$(document).on('turbolinks:load', function() {

    $('.navbar-item.has-dropdown').click(function() {
        $(this).addClass("is-active");
	$(this).children(".navbar-dropdown").addClass("is-active");
    });
    $('.navbar-item.has-dropdown').mouseleave(function() {
        $(this).removeClass("is-active");
        $(this).children(".navbar-dropdown").removeClass("is-active");
    });

});

