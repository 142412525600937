
function clickAbonnement(id) {
    if($('.abo-'+id).hasClass("has-text-success")) {
        $('#item-'+id).removeClass('abo');
        $.getScript("/abonnement/retire/"+id+'.js');
        $('.if-abo-true,.if-abo-false').removeClass("is-abo-true");
        $('.if-abo-true,.if-abo-false').addClass("is-abo-false");
    } else {
        $('#item-'+id).addClass('abo');
        $.getScript("/abonnement/ajoute/"+id+'.js');
        $('.if-abo-true,.if-abo-false').removeClass("is-abo-false");
        $('.if-abo-true,.if-abo-false').addClass("is-abo-true");
    }
}

window.clickAbonnement = clickAbonnement;
