// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You’re encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it’ll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// https://stackoverflow.com/questions/55895494/is-not-defined-when-installing-jquery-in-rails-via-webpack
require("jquery")
window.jQuery = $;
window.$ = $;

require("jquery-ui/ui/widgets/autocomplete")

var Isotope = require('isotope-layout');
window.Isotope = Isotope;

var OpenLayers = require("ol");

import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
window.TileLayer = TileLayer;
window.VectorLayer = VectorLayer;

import {OSM, Vector as VectorSource} from 'ol/source';
window.OSM = OSM;
window.VectorSource = VectorSource;

import {transform, transformExtent} from 'ol/proj';
window.transform = transform;

import {Circle as CircleStyle, Fill, Stroke, Style, RegularShape} from 'ol/style';
window.RegularShape = RegularShape;
window.CircleStyle = CircleStyle;
window.Stroke = Stroke;
window.Fill = Fill;
window.Style = Style;

import GeoJSON from 'ol/format/GeoJSON';
window.GeoJSON = GeoJSON;

import Point from 'ol/geom/Point';
window.Point = Point;

window.OpenLayers = OpenLayers;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
