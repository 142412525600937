
$(document).on('turbolinks:load', function() {

    // (+) pour enlever les messages
    $(".notification .delete").click(function(event) {
        event.target.parentElement.remove();
    });

    // Check for click events on the navbar burger icon
    $(".navbar-burger").click(function() {

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        $(".navbar-burger").toggleClass("is-active");
        $(".navbar-menu").toggleClass("is-active");

    });
});
